<template>
    <div class="contain">
        <searchBar :searchInput="false"></searchBar>
        <div class="bgf">
            <br />
            <div class="fs_24 center color_333 padding_20 bottom_link">
                {{ title }}
            </div>
            <el-form :model="ruleForm"  :rules="rules" ref="ruleForm" label-position="top" :hide-required-asterisk="true" class="form">
                <div class="fs_20 color_333 padding_20 padding_lr_0 bottom_link mb_15">
                    <span class="bold mr_5">基本信息</span><span class="sub_color fs_16">(必填)</span>
                </div>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="任务标题" prop="reward_name">
                            <el-input type="text" placeholder="20字以内" maxlength="20" @input="rewardNameInput('reward_name')" v-model.trim="ruleForm.reward_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="任务介绍" prop="desc">
                            <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" resize="both" maxlength="500" placeholder="请详细描述本任务的介绍信息，例如：任务的背景、工作的内容、考核标准等等..." v-model="ruleForm.desc"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="起止时间" prop="fromTo">
                            <el-date-picker v-model="ruleForm.fromTo" :disabledDate="disabledDate" :editable="false" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" @change="timechange"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="接单主体" prop="role_limit">
                            <el-select v-model="ruleForm.role_limit" :disabled="id&&inProgress>0 ? true : false" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" class="limit">
                        <el-form-item label="接单限制" prop="self_limit" class="flex_">
                            <el-radio-group v-model="ruleForm.self_limit" :disabled="id&&inProgress>0 ? true : false" size="medium">
                                <el-radio :label="0" border>不限制</el-radio>
                                <!-- <el-radio :label="1" border>仅允许指定合作方</el-radio> -->
								<el-radio :label="2" border>不公开</el-radio>
								<el-radio :label="3" border>指定手机号可见</el-radio>
                            </el-radio-group>
                            <!-- <el-tooltip content="top center" placement="top" effect="light">
                                <template #content>
                                    <div class="flex_cen">通过批量导入指定的合作方<a href="https://hr.zhiluinfo.com/import-batch" class="color" target="_black">去查看</a></div>
                                </template>
                                <img src="@/assets/imgs/wh.png" style="width:16px;height:16px;margin-left:8px;" alt="">
                            </el-tooltip> -->
                        </el-form-item>
                    </el-col>
					<template v-if="ruleForm.self_limit === 3">
						<el-col :span="24" v-if="is_appointor">
							<div class="gift_body">
								<el-input
									type="textarea"
									:autosize="{ minRows: 8, maxRows: 8 }"
									resize="none" maxlength="500"
									placeholder="请输入手机号，回车将添加下一个"
									v-model="appointor"
								>
								</el-input>
								<div class="flex gift_button">
									<el-button class="flex_1" type="text" @click.stop="closeAppointor">
										取消
									</el-button>
									<el-button class="flex_1" type="text" @click.stop="postAppointor">
										保存
									</el-button>
								</div>
							</div>
						</el-col>
						<template v-else>
							<template v-for="(item, index) in appointor_list" :key="index">
								<el-col class="gift_body" :span="4">
									<el-button class="addGift-btn" plain>
										{{ item }}
									</el-button>
								</el-col>
							</template>
							<el-col class="gift_body" :span="4">
								<el-button plain class="addGift-btn" @click.stop="addAppointor">
									+ 编辑手机号
								</el-button>
							</el-col>
						</template>
					</template>
                </el-row>
                <div class="fs_20 color_333 padding_20 padding_lr_0 bottom_link mb_15">
                    <span class="bold mr_5">报酬约定</span><span class="sub_color fs_16">(必填)</span>
                </div>
                <el-row :gutter="20">
					<el-col :span="24" class="limit">
                        <el-form-item label="合同约定" prop="is_contract" class="flex_">
                            <el-radio-group v-model="ruleForm.is_contract" size="medium">
								<el-radio :label="1" border>是</el-radio>
								<el-radio :label="0" border>否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
					<template v-if="ruleForm.is_contract == 0">
						<el-col :span="6">
							<el-form-item label="报酬" prop="settle_money">
								<el-input v-model.trim="ruleForm.settle_money" type="number" maxlength="10" show-word-limit placeholder="请填写任务报酬"><template #append>元</template></el-input>
							</el-form-item>
						</el-col>
						<!-- <el-col :span="1">
							<el-form-item :label="'&nbsp'">
								<div class="color_666 fs_14 h_44 center">元 /</div>
							</el-form-item>
						</el-col> -->
						<el-col :span="6">
							<el-form-item :label="'&nbsp'" prop="price_method" class="not_spot">
								<el-select v-model="ruleForm.price_method" @change="priceMethodChange" ref="price_method" placeholder="请选择计价方式">
									<el-option v-for="item in dateType" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
									<div style="padding: 0 15px">
										<el-input type="text" placeholder="如：小时" @keyup.enter="otherBlur(true)" @input="otherBlur(false)" show-word-limit :maxlength="5" v-model.trim="other_price_method"></el-input>
									</div>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item :label="'&nbsp'" prop="price_type" class="not_spot">
								<el-select v-model="ruleForm.price_type" placeholder="请选择结算周期">
									<el-option v-for="item in payModel" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item :label="'&nbsp'" prop="settle_type" class="not_spot">
								<el-select v-model="ruleForm.settle_type" placeholder="请选择结算形式">
									<el-option v-for="item in payType" :key="item.value" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</template>
                </el-row>
                <div class="fs_20 color_333 padding_20 padding_lr_0 bottom_link mb_15">
                    <span class="bold mr_5">开票信息</span>
                </div>
                <el-row :gutter="20">
                    <el-col :span="24" class="limit">
                        <el-form-item label="支付税费方" prop="tax_payer" class="flex_">
                            <el-radio-group v-model="ruleForm.tax_payer" size="medium">
                                <el-radio :label="1" border>企业支付</el-radio>
                                <el-radio :label="2" border>接单人支付</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <div class="flex" style="margin-top:-12px">
                            <p class="fs_14 color_999">接单人在任务中获得的收入，需按规定及时进行完税，请选择本次任务中支付税费及服务费的对象</p>
                        </div>
                        <div class="flex_c mt_20">
                            <p class="fs_16 color_666">开票费用</p>
                            <el-tooltip content="top center" placement="top" effect="light">
                                <template #content>
                                    <div class="flex_cen">该费用由平台定制，如有疑问请联系业务员或咨询客服</div>
                                </template>
                                <img src="@/assets/imgs/wh.png" style="width:16px;height:16px;margin-left:8px;" alt="">
                            </el-tooltip>
                        </div>
                        <div class="invoice-info">
                            <el-row :gutter="10">
                                <el-col :span="4" class="flex_c invoice-item tax fs_14">
                                    <span class="color_999">综合税率：</span>
                                    <span class="mt_3">{{ taxRule.synthesis_fee?taxRule.synthesis_fee+'%' : '/' }}</span>
                                </el-col>
                                <el-col :span="4" class="flex_c invoice-item tax fs_14">
                                    <span class="color_999">快递费：</span>
                                    <span>{{ taxRule.express_fee ? '￥'+taxRule.express_fee : '/' }}</span>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="flex mt_20">
                            <p class="fs_16 color_666">发票信息</p>
                            <p class="fs_16 color btn" @click="editInvoice">编辑</p>
                        </div>
                        <div class="invoice-info">
                            <el-row :gutter="5">
                                <el-col :span="10" class="flex_c invoice-item fs_14">
                                    <span class="label color_999">企业发票抬头：</span>
                                    <span class="one flex_1">{{ invoiceDetail.company_name }}</span>
                                </el-col>
                                <el-col :span="8" class="flex_c invoice-item fs_14">
                                    <span class="label color_999">税务登记号：</span>
                                    <span class="mt_3">{{ invoiceDetail.creditcode }}</span>
                                </el-col>
                                <el-col :span="6" class="flex_c invoice-item fs_14">
                                    <span class="label color_999">发票类型：</span>
                                    <span>{{ invoiceDetail.invoice_type_text || '/' }}</span>
                                </el-col>
                                <el-row v-show="openDetail" style="width:100%">
                                    <el-col :span="10" class="flex_c invoice-item fs_14">
                                        <span class="label color_999">基本开户账号：</span>
                                        <span class="mt_3">{{ invoiceDetail.bank_account || '/' }}</span>
                                    </el-col>
                                    <el-col :span="8" class="flex_c invoice-item fs_14">
                                        <span class="label color_999">开户银行名称：</span>
                                        <span>{{ invoiceDetail.bank_name || '/' }}</span>
                                    </el-col>
                                    <el-col :span="6" class="flex_c invoice-item fs_14">
                                        <span class="label color_999">注册固定电话：</span>
                                        <span class="mt_3">{{ invoiceDetail.phone || '/' }}</span>
                                    </el-col>
                                    <el-col :span="24" class="flex_c invoice-item fs_14">
                                        <span class="label color_999">注册场所地址：</span>
                                        <span class="">{{ invoiceDetail.address || '/' }}</span>
                                    </el-col>
                                </el-row>
                                <el-col :span="10" class="flex_c invoice-item fs_14 tax">
                                    <span class="label color_999">开票内容：</span>
                                    <el-input type="text" placeholder="请填写开票内容" v-model.trim="ruleForm.invoice_content"></el-input>
                                </el-col>
                                <el-col :span="8" class="flex_c invoice-item fs_14 tax">
                                    <span class="label color_999">发票备注：</span>
                                    <el-input type="text" placeholder="请填写发票备注" v-model.trim="ruleForm.invoice_remark"></el-input>
                                </el-col>
                            </el-row>
                        </div>
                            <div class="flex_cen mt_5 fs_13 color">
                                <p class="btn" @click="openDetail=!openDetail">{{!openDetail?'展开':'收起'}} <span :class="!openDetail?'el-icon-arrow-down':'el-icon-arrow-up'"></span></p>
                            </div>
                    </el-col>
                </el-row>
                <div class="fs_20 bold color_333 padding_20 padding_lr_0 bottom_link mb_15">
                    联系方式
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="联系人" prop="publish_contacts">
                            <el-input type="text" placeholder="请填写联系人" v-model.trim="ruleForm.publish_contacts"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系方式" prop="publish_tel">
                            <el-input type="text" placeholder="请填写联系方式" v-model.trim="ruleForm.publish_tel"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item>
                    <el-button type="primary" class="submit fs_18" :loading="loading" @click="submitForm('ruleForm')">
                        {{ id ? '提 交' : '发 布' }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <invoice-dialog ref="invoice" @cb="getInvoice"></invoice-dialog>
    </div>
</template>

<script>
import { isPhone } from "@/common/validate"
import { l_getItem } from "@/common/cache"
import invoiceDialog from '@/components/invoice-dialog'
export default {
	name: "EmploymentPost",
	components: { invoiceDialog },
	data() {
		const validatePhone = (rule, value, callback) => {
			if (!isPhone(value)) {
				callback(new Error("请填写正确的手机号"))
			} else {
				callback()
			}
		}
		const validateSettle_money = (rule, value, callback) => {
			if (!value) {
				callback(new Error("请填写任务报酬"))
			} else if (value <= 0) {
				callback(new Error("报酬金额不能小于0"))
			} else {
				callback()
			}
		}
		return {
			id: null,
			title: "发布新任务",
			is_appointor: false,
			appointor: '',
			appointor_list: '',
			loading: false,
			invoiceDetail: {},
			hasAgr: 0,
			inProgress: 0, // 任务是否有人接单
			taxRule: {},
			isCallback: false,
			openDetail: false,
			other_price_method: '', // 其他计价方式
			ruleForm: {
				reward_name: "",
				self_limit: 0,
				is_contract: 0,
				desc: "",
				fromTo: [],
				role_limit: "",
				settle_money: "",
				price_method: "",
				tax_payer: 1,
				invoice_content: "",
				invoice_remark: "",
				price_type: "",
				settle_type: "",
				publish_contacts: "",
				publish_tel: ""
			},
			invoiceOptions: [ {
				value: 0,
				label: '增值税普通发票'
			}, {
				value: 1,
				label: '增值税专用发票'
			} ],
			rules: {
				reward_name: [
					{ required: true, message: "请填写任务标题", trigger: "blur" },
					{ min: 1, max: 20, message: "长度在20字以内", trigger: "blur" }
				],
				desc: [
					{ required: true, message: "请填写任务介绍", trigger: "blur" },
					{ min: 1, max: 500, message: "内容在500字以内", trigger: "blur" }
				],
				fromTo: [
					{ required: true, message: "请选择起止时间", trigger: "change" }
				],
				role_limit: [
					{ required: true, message: "请选择接单主体", trigger: "change" }
				],
				tax_payer: [
					{ required: true, message: "请选择支付税费方", trigger: "change" }
				],
				settle_money: [
					{ required: true, validator: validateSettle_money, trigger: "blur" }
				],
				price_method: [
					{ required: true, message: "请选择计价方式", trigger: "change" }
				],
				price_type: [
					{ required: true, message: "请选择结算周期", trigger: "change" }
				],
				settle_type: [
					{ required: true, message: "请选择结算形式", trigger: "change" }
				],
				publish_contacts: [
					{ required: true, message: "请填写联系人", trigger: "blur" }
				],
				publish_tel: [
					{ required: true, trigger: "blur", message: "请填写联系方式" },
					{ validator: validatePhone, trigger: "blur" }
				]
			},
			options: [
				{ value: 1, label: "个体工商户" },
				{ value: 2, label: "个人独资企业" },
				{ value: 4, label: "自然人" }
			],
			dateType: [
				{
					value: "天",
					label: "1天"
				},
				{
					value: "周",
					label: "1周"
				},
				{
					value: "月",
					label: "1月"
				},
				{
					value: "单",
					label: "1单"
				},
				{
					value: "件",
					label: "1件"
				},
				{
					value: "合同约定",
					label: "合同约定"
				}
			],
			payModel: [
				{
					value: "日结",
					label: "日结"
				},
				{
					value: "周结",
					label: "周结"
				},
				{
					value: "月结",
					label: "月结"
				},
				{
					value: "项目完成发放",
					label: "项目完成发放"
				},
				{
					value: "按协商发放",
					label: "按协商发放"
				},
				{
					value: "合同约定",
					label: "合同约定"
				}
			],
			payType: [
				{
					value: "现金",
					label: "现金"
				},
				{
					value: "支票",
					label: "支票"
				},
				{
					value: "微信",
					label: "微信"
				},
				{
					value: "支付宝",
					label: "支付宝"
				}
			],
			disabledDate: time=> {
				return  time.getTime() < new Date().getTime()
			}
		}
	},
	created() {
		this.id = this.$route.query.id || ""
		this.hasAgr = this.$route.query.a || 0
		this.inProgress = this.$route.query.n || 0
		if (this.id) {
			this.title = "编辑任务"
			this.$route.meta.subtitle = '编辑任务'
			this.getDetail()
		} else {
			let userInfo = l_getItem("USERINFO")
			this.ruleForm.publish_contacts = userInfo.realname
			this.ruleForm.publish_tel = userInfo.mobile
		}
		this.getTaxRule()
		console.log(this.id&&this.inProgress>0)
	},
	computed: {
		isFull() {
			if(this.invoiceDetail.invoice_type === 1) {
				return this.invoiceDetail.creditcode && this.invoiceDetail.company_name && this.invoiceDetail.invoice_type!==false
                && this.invoiceDetail.bank_name && this.invoiceDetail.bank_account && this.invoiceDetail.phone && this.invoiceDetail.address
			}else if(this.invoiceDetail.invoice_type === 0) {
				return this.invoiceDetail.creditcode && this.invoiceDetail.company_name && this.invoiceDetail.invoice_type!==false
			}
			return false
		}
	},
	watch: {
		isFull(n) {
			if(!n && !this.isCallback) {
				this.$confirm('请先完善发票信息', "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					showCancelButton: false,
					showClose: false,
					closeOnClickModal: false,
					closeOnPressEscape: false,
					type: "warning"
				}).then(() => {
					this.$refs.invoice.dialogVisible = true
					this.$refs.invoice.getInvoice()
					// this.$refs.invoice.ruleForms = this.invoiceDetail
				}).catch(()=>{
					this.$router.go(-1)
				})
			}
		}
	},
	methods: {
		rewardNameInput(key) {
			this.ruleForm[key] = this.ruleForm[key].replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')
		},
		getTaxRule() {
			this.$axios({
				url: `/ent/v3/reward/rule`,
				method: "GET"
			}).then(res => {
				if (res.code === 200) {
					if(res.data.err) {
						this.$confirm(res.msg, "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							showCancelButton: false,
							showClose: false,
							closeOnClickModal: false,
							closeOnPressEscape: false,
							type: "warning"
						}).then(() => {
							this.$router.go(-1)
						})
						return
					}
					let synthesis_fee = 0
					if(res.data.service_fee && res.data.rate) {
						synthesis_fee = Number(res.data.service_fee)*100 + Number(res.data.rate)*100
						synthesis_fee = (synthesis_fee/100).toFixed(2)
					}else {
						synthesis_fee = res.data.service_fee || res.data.rate
					}
					res.data.synthesis_fee = synthesis_fee
					this.taxRule = res.data
					this.getInvoice()
				}
			})
		},
		getDetail() {
			this.$axios({
				url: `/ent/v3/reward/${this.id}`,
				method: "GET"
			}).then(res => {
				if (res.code === 200) {
					this.ruleForm.fromTo = [ res.data.publish_time, res.data.deadline ]
					let is_contract = res.data.settle_money === 0
					this.ruleForm = { ...this.ruleForm,  ...res.data, is_contract }
					if(res?.data?.appointor){
						this.appointor_list = res.data.appointor.split(/[,]/g).filter(item => item != '')
						this.appointor = res.data.appointor.replace(/[,]/g, "\n")
					}
				}
			})
		},
		getInvoice(isCallback=false) {
			this.isCallback = isCallback
			this.$axios({
				url: '/ent/v3/usercenter/company/invoice',
				method: 'GET',
				hideLoading: true
			}).then(res=>{
				if(res.code === 200) {
					this.invoiceDetail = res.data.invoice
					if(this.invoiceDetail.invoice_type!==false) {
						this.invoiceDetail.invoice_type_text = this.invoiceOptions[this.invoiceDetail.invoice_type].label
					}else {
						this.invoiceDetail.invoice_type = '-1'
					}
					this.isCallback = false // 防止编辑发票信息之后再次弹框
				}
			})
		},
		timechange(e) {
			this.ruleForm.publish_time = e[0]
			this.ruleForm.deadline = e[1]
		},
		// 计价方式选择，清空（其他计价方式）输入框内容
		priceMethodChange() {
			this.other_price_method = ''
		},
		// 其他计价方式
		otherBlur(blur) {
			// if(this.other_price_method) {
			this.other_price_method = this.other_price_method.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')
			this.ruleForm.price_method = this.other_price_method
			blur && this.$refs.price_method.blur()
			// }
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if(!this.isFull) {
						this.$confirm('请先完善发票信息', "提示", {
							confirmButtonText: "确定",
							showCancelButton: false,
							showClose: false,
							closeOnClickModal: false,
							closeOnPressEscape: false,
							type: "warning"
						}).then(() => {
							this.$refs.invoice.dialogVisible = true
							this.$refs.invoice.getInvoice()
							// this.$refs.invoice.ruleForms = this.invoiceDetail
						})
						return
					}
					this.loading = true
					let url = `/ent/v3/reward`
					let method = "POST"
					if (this.id) {
						url = `/ent/v3/reward/${this.id}`
						method = "PUT"
					}
					const settle_money = this.ruleForm.is_contract !== 1 ? this.ruleForm.settle_money : 0
					this.$axios({
						url,
						method,
						data: { ...this.ruleForm, appointor: this.appointor_list, settle_money },
						shake: true,
						hideLoading: true
					}).then(res => {
						setTimeout(() => {
							this.loading = false
						}, 900)
						if (res.code === 200) {
							if(this.hasAgr>0) {
								this.$message.success('修改成功')
								setTimeout(() => {
									this.$router.replace('/employment')
								}, 1000)
							}else {
								this.$router.replace(`/employment/upload-protocol/${this.id || res.data.id}`)
							}

						}
					})
				} else {
					console.log("error submit!!")
					this.$message.warning('请填写完整信息')
					return false
				}
			})
		},
		editInvoice() {
			this.$refs.invoice.dialogVisible = true
			this.$refs.invoice.getInvoice()
			// this.$refs.invoice.ruleForms = this.invoiceDetail
		},
		keyupAppointor(e){
			if (e.keyCode === 13) {
				console.log(111)
			}
		},
		postAppointor(){
			this.appointor_list = this.appointor.split(/[\n,]/g).filter(item => item != '')
			this.closeAppointor()
		},
		addAppointor() {
			this.is_appointor = true
		},
		closeAppointor() {
			this.is_appointor = false
		}
	}
}
</script>

<style lang="less" scoped>
.w_50 {
    width: 50%;
}
.h_44 {
    height: 44px;
    line-height: 44px;
}
.padding_lr_0 {
    padding-left: 0;
    padding-right: 0;
}

/deep/.invoice-info {
    width: 1000px;
    // height: 150px;
    background: #F9F9F9;
    border: 1px solid #E3E3E3;
    margin-top: 10px;
    border-radius: 2px;
    padding: 0 20px;
    .invoice-item {
        height: 48px;
        .label {
            width: 112px;
        }
        &.tax {
            border: none !important;
        }
    }
    .invoice-item {
        border-bottom: 1px dashed #e3e3e3;

    }
    .el-input__inner {
        border: none !important;
        padding-left: 25px;
    }
}
/deep/.not_spot .el-form-item__label::before {
    color: #fff !important;
}
/deep/.form {
    padding: 30px 100px 90px;
    .el-select {
        width: 100%;
    }
    .el-form-item__label {
        padding: 0 !important;
    }
    .el-input__inner {
        height: 44px;
        background-color: #f9f9f9;
        border-radius: 0px;
        width: 100% !important;
    }
    .el-textarea__inner {
        height: 44px;
        background-color: #f9f9f9;
        border-radius: 0px;
    }
    .el-range-editor--small {
        .el-range-separator {
            font-size: 20px;
            line-height: 34px;
            width: 10%;
        }
        .el-range-input {
            font-size: 14px;
            background-color: #f9f9f9;
        }
        .el-range__icon {
            width: 10%;
        }
        .el-range__close-icon {
            width: 10%;
        }
    }
    .el-input {
        .el-input__count {
            .el-input__count-inner {
                background-color: transparent;
            }
        }
    }
    .submit {
        margin: 0 auto;
        width: 200px;
        line-height: 26px;
        height: 52px;
        display: block;
        margin-top: 50px;
        border-radius: 2px;
    }
}

/deep/.el-form-item__label {
    font-size: 16px;
}
/deep/.el-input-group__append {
    background-color: #f9f9f9;
}
.limit {
    /deep/.el-form-item--small .el-form-item__content {
        display: flex;
        align-items: center;
    }
}
/deep/.gift_body {
        background: #fff;
        // border: 1px solid #dcdfe6;
        color: #606266;
        .add_gift {
            text-align: center;
            margin-right: 10px;
        }

        .el-input__inner {
            text-align: center;
            padding: 5px 10px;
        }

        .gift_button {
            position: relative;
			border-right: 1px solid #dcdfe6;
			border-left: 1px solid #dcdfe6;
			border-bottom: 1px solid #dcdfe6;
        }
        .el-button {
            width: 100%;
            height: 44px;
            margin: 0 auto;
            &:first-child {
                border-right: 1px solid #dcdfe6;
            }
        }
		.addGift-btn {
			height: 36px;
			margin-bottom: 20px;
			color: #1890ff;
			border-color: #1890ff;
			border-right: 1px solid#1890ff !important;
		}
    }
</style>
